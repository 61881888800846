import { EDITOR_TYPES } from 'src/enums';
import {
  CollegeModel,
  CoreModel,
  ExperienceModel,
  ExtracurricularModel,
  HobbiesModel,
  LanguageModel,
  OrganizationModel,
  SchoolModel,
  TechSkillModel,
  UserModel,
  VolunteeringModel,
  CustomSectionModel,
} from '../models';

export const resumeElementModelMap = {
  [EDITOR_TYPES.COLLEGES]: CollegeModel,
  [EDITOR_TYPES.HIGH_SCHOOLS]: SchoolModel,
  [EDITOR_TYPES.EXPERIENCE]: ExperienceModel,
  [EDITOR_TYPES.EXTRA_CURRICULAR]: ExtracurricularModel,
  [EDITOR_TYPES.HOBBIES]: HobbiesModel,
  [EDITOR_TYPES.LANGS]: LanguageModel,
  [EDITOR_TYPES.MAIL_ADDRESS]: UserModel,
  [EDITOR_TYPES.ORGS]: OrganizationModel,
  [EDITOR_TYPES.PROF_SUMMARY]: UserModel,
  [EDITOR_TYPES.TECH_SKILLS]: TechSkillModel,
  [EDITOR_TYPES.VOLUNTEERING]: VolunteeringModel,
  [EDITOR_TYPES.CUSTOM_SECTIONS]: CustomSectionModel,
};
