import React, { FC, useContext, useEffect, useState } from 'react';
import { UserModel } from 'src/models';
import { IDataService, useDataService } from '../hooks';

interface IUserContext {
  user: any;
  setUser: (user: UserModel, accessToken: string) => void;
  httpService: IDataService;
  isLoggedIn: boolean;
}

const defaultState = {
  user: null,
  setUser: (user: UserModel) => {},
  httpService: {} as IDataService,
  isLoggedIn: false,
};

const UserContext = React.createContext<IUserContext>(defaultState);

export const UserProvider: FC<any> = ({ children }) => {
  const [ user, setUser ] = useState(new UserModel());
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);

  const httpService = useDataService();

  useEffect(() => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const user = JSON.parse(localStorage.getItem('user') || null);
      httpService.setAccessToken(accessToken);
      setIsLoggedIn(Boolean(accessToken));
      setUser(new UserModel(user));
    } catch (error) {
      console.error('JSON Paring Error', error);
    }
  }, []);

  const contextValue = {
    user,
    httpService,
    isLoggedIn,
    setUser: (user: UserModel, accessToken: string) => {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      setIsLoggedIn(Boolean(accessToken));
    },
  };
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
