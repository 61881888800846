import { ITitleAndLines, Utilities } from '@job-commander/shared';
import moment from 'moment';
import { ICollege } from 'src/interfaces';
import { CoreModel } from './Core.model';

export class CollegeModel extends CoreModel implements ITitleAndLines {
  collegeChoiceNo: number;
  degreeLevelChoiceNo: number;
  minorDegree1ChoiceNo: number;
  present: boolean;
  relevantCourseWork: string;
  feats: string;
  gpa: string;
  gpaChoiceNo: number;
  // gradYear: moment.Moment;
  // Missing in API
  collNameOther: string;
  // UI Fields
  major: number[];

  constructor(data?: Partial<CollegeModel>) {
    super(data);
    Object.assign(this, data);
  }

  public get _grandYear(): string {
    return this.gradYear?.year().toString();
  }

  public get title(): string {
    return this.name;
  }

  public get lines(): string[] {
    return [ this.feats, this._grandYear ];
  }

  public get subTitle(): string[] {
    return [ this.name, this.minorDegree1ChoiceNo?.toString() ];
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<ICollege>) {
    return new CollegeModel({
      ...data,
      ...this.deserializeDates(data),
      major: [ data?.majorDegree1ChoiceNo, data?.majorDegree2ChoiceNo ],
    });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this, majorDegree1ChoiceNo: this.major[0], majorDegree2ChoiceNo: this.major[1] };
  }
}
