import { useNotification } from '../context/NotificationContext';

// Parameter is the boolean, with default "false" value
export const useAlert = () => {
  const notification = useNotification();

  const success = (message: string, description?: string) => {
    notification.open({
      message,
      description,
      className: 'success-notification',
    });
  };

  const error = (message: string, description?: string) => {
    notification.open({
      message,
      description,
      className: 'error-notification',
    });
  };
  return { success, error };
};
