import React, { useState, useContext, FC } from 'react';
import { notification } from 'antd';
import './Notification.less';
const NotificationContext = React.createContext({
  open: (data: any) => {},
  exception: (data: any, error: any) => {},
  exceptions: [],
  setExceptions: (data: any) => {},
});

export const NotificationProvider: FC<any> = ({ children }) => {
  const [ exceptions, setExceptions ] = useState([]);

  const contextValue = {
    exceptions,
    setExceptions,
    open(data: any) {
      notification.open({
        duration: 8,
        ...data,
      });
    },
    exception(from: any, data: any) {
      // if (SHOW_DEBUG !== true) return;
      // setExceptions(exceptions => [{ key: Date.now(), from, data }, ...exceptions ]);
    },
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};

// useNotification()
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
