import React, { useState, FC } from 'react';

const AppContext = React.createContext({
  title: '',
  icon: null,
  setTitle: (title: string) => {},
  setIcon: (icon: any) => {},
});

export const AppProvider: FC<any> = ({ children }) => {
  const [ title, setTitle ] = useState(null);
  const [ icon, setIcon ] = useState(null);

  const contextValue = { title, setTitle, icon, setIcon };

  return <AppContext.Provider value={contextValue as any}>{children}</AppContext.Provider>;
};

export { AppContext };
