import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserProvider } from './context';
import { DialogProvider } from '@surinderlohat/react-dialog';
import { NotificationProvider } from '@job-commander/shared';
import './App.less';

const Authorization = lazy(() => import(/* webpackPrefetch: true */ './pages/Auth/Authorization'));
const Dashboard = lazy(() => import(/* webpackPrefetch: true */ './pages/Dashboard/Dashboard'));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading ...</div>}>
        <NotificationProvider>
          <Elements stripe={stripePromise}>
            <UserProvider>
              <DialogProvider>
                <Switch>
                  <Route path="/auth" component={Authorization} />
                  <Route path="/a" component={Dashboard} />
                  <Redirect path="/*" to="/auth" />
                </Switch>
              </DialogProvider>
            </UserProvider>
          </Elements>
        </NotificationProvider>
      </Suspense>
    </Router>
  );
};

export default App;
