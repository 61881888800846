import { SUBSCRIPTION_TYPE } from 'src/enums';

export const resumeData = {
  colleges: [
    {
      seq: 1,
      collegeChoiceNo: 2,
      degreeLevelChoiceNo: 2,
      majorDegree1ChoiceNo: 3,
      majorDegree2ChoiceNo: 3,
      minorDegree1ChoiceNo: 4,
      startDate: '1974-09-01T00:00:00',
      present: true,
      endDate: '1978-06-01T00:00:00',
      relevantCourseWork: 'Music Flowers Cooking',
      feats: 'Leaping tall buildings with a single bound',
      gpa: '3.2',
      gpaChoiceNo: 7,
      gradYear: '1978',
    },
    {
      seq: 2,
      collegeChoiceNo: 1,
      degreeLevelChoiceNo: 1,
      majorDegree1ChoiceNo: 1,
      majorDegree2ChoiceNo: 2,
      minorDegree1ChoiceNo: 3,
      startDate: '1970-09-01T00:00:00',
      present: true,
      endDate: '1974-06-01T00:00:00',
      relevantCourseWork: 'Music Flowers Cooking',
      feats: 'Leaping tall buildings with a single bound',
      gpa: '3.2',
      gpaChoiceNo: 7,
      gradYear: '1974',
    },
  ],
  extracurricular: [
    {
      seq: 1,
      role: 'Chef',
      org: 'Feeding the Homeless',
      location: 'San Fancisco',
      startDate: '2002-09-01T00:00:00',
      endDate: '2003-10-01T00:00:00',
      summary: 'Composed recipes and procedures for cooking for 10,000 people at a time.',
      extraCurMonthsChoiceNo: 5,
    },
    {
      seq: 2,
      role: 'Logistics',
      org: 'Feeding the Homeless',
      location: 'San Fancisco',
      startDate: '2002-07-01T00:00:00',
      endDate: '2002-09-01T00:00:00',
      summary: 'Generated routes for feeding the homeless on a weekly schedule',
      extraCurMonthsChoiceNo: 2,
    },
  ],
  highSchools: [
    {
      seq: 1,
      name: 'Stamford High School',
      endDate: '1970-06-01T00:00:00',
      present: false,
      gpa: '4.0',
      feats: 'School Forger of the year, fours years in a row',
      gpaChoiceNo: 11,
      gradYear: 1970,
    },
  ],
  hobbies: [
    {
      seq: 1,
      order: 1,
      name: 'Tropical Fish',
      hobbyLevel: 3,
      summary: 'Kept and tropical fish for feeing my family',
    },
  ],
  langs: [
    {
      seq: 1,
      order: 1,
      name: 'Fortran 77',
      langLevel: 3,
    },
    {
      seq: 2,
      order: 2,
      name: 'DEC PDP Macro-11',
      langLevel: 2,
    },
    {
      seq: 3,
      order: 3,
      name: 'Pascal',
      langLevel: 1,
    },
    {
      seq: 4,
      order: 4,
      name: 'C++',
      langLevel: 4,
    },
    {
      seq: 5,
      order: 5,
      name: 'C#',
      langLevel: 4,
    },
    {
      seq: 6,
      order: 6,
      name: 'SQL',
      langLevel: 4,
    },
  ],
  mailAddress: {
    firstName: 'Surinder',
    lastName: 'Singh',
    address: '180 Glenbrook Road Apt 45',
    city: 'stamford',
    state: 'Ct',
    phoneNo: 9855507325,
    email: 'abc@gmail.com',
    zip: '06902',
  },
  orgs: [
    {
      seq: 1,
      name: 'End the war org',
      role: 'Speech writer',
      location: 'California Desert',
      startDate: '1978-06-01T00:00:00',
      endDate: '1980-06-01T00:00:00',
      present: false,
      summary: 'Wrote speeches for rallies',
      orgMonthsChoiceNo: 5,
    },
  ],
  profSummary: {
    summary: 'I want to be president of the United States.',
  },
  techSkills: [
    {
      seq: 1,
      order: 1,
      name: 'Picking peaches',
      techSkillLevel: 3,
    },
    {
      seq: 2,
      order: 2,
      name: 'Picking apples',
      techSkillLevel: 3,
    },
    {
      seq: 3,
      order: 3,
      name: 'Picking anything',
      techSkillLevel: 3,
    },
  ],
  volunteering: [
    {
      candId: 0,
      seq: 1,
      role: 'Grunt',
      org: 'Beach Cleanup Organization',
      location: 'The beach',
      startDate: '1980-06-01T00:00:00',
      endDate: '1980-06-02T00:00:00',
      summary: 'Picking up litter on beaches',
    },
  ],
  experience: [
    {
      seq: 1,
      company: 'Self employed',
      title: 'Sole Proprieter',
      startDate: '2010-01-01T00:00:00',
      endDate: '2015-01-01T00:00:00',
      yearsExperChoiceNo: 3,
      induJobId: 1,
      induId: 1,
      city: 'Stamford',
      state: 'C ',
      summary: 'Any engineering',
    },
    {
      seq: 2,
      company: 'Norden Systems of Norwalk Connecticut',
      title: 'Engineer',
      startDate: '2009-01-01T00:00:00',
      endDate: '2010-01-01T00:00:00',
      yearsExperChoiceNo: 2,
      induJobId: 1,
      induId: 1,
      city: 'Stamford',
      state: 'C ',
      summary: 'Test engineering',
    },
    {
      seq: 3,
      company: 'Norden Systems of Norwalk Connecticut',
      title: 'Junior Engineer',
      startDate: '2004-01-01T00:00:00',
      endDate: '2009-01-01T00:00:00',
      yearsExperChoiceNo: 3,
      induJobId: 1,
      induId: 1,
      city: 'Norwalk',
      state: 'C ',
      summary: 'Lab work: Integration with firware, prototyping. Drawing schematics',
    },
  ],
  customSections: [
    {
      candId: 0,
      sectionName: 'Clandenstine Activities',
      seq: 1,
      sectionOrder: 1,
      role: 'Head Chemist',
      location: 'Underground',
      org: 'Mafioso',
      startDate: '2001-01-01T00:00:00',
      endDate: '2001-06-01T00:00:00',
      summary: 'Created all formulas and procedures to create bootleg pennicillin',
    },
    {
      candId: 0,
      sectionName: 'Gardening',
      seq: 2,
      sectionOrder: 2,
      role: 'Head Gardener',
      location: 'Underground',
      org: 'Organic Groceries',
      startDate: '2001-06-01T00:00:00',
      endDate: '2002-07-01T00:00:00',
      summary: 'Created Planting schedules for the organization.',
    },
  ],
};

// Subscription Data from API
export const userSubscription = {
  productClass: 'RC',
  productName: 'RC Plus Subscription Monthly',
  productDescr: 'Plus (10 Color Choice  Resume) 20 Questions',
  mayUseTraitCount: 7,
  subscriptionType: SUBSCRIPTION_TYPE.PREMIUM,
  templateName: [
    'BASIC-T1',
    'BASIC-T2',
    'BASIC-T3',
    'BASIC-T4',
    'BASIC-T5',
    'BASIC-T6',
    'BASIC-T7',
    'PLUS-T1',
    'PLUS-T2',
    'PLUS-T3',
    'PLUS-T4',
    'PLUS-T5',
    'STANDARD-T1',
    'STANDARD-T2',
    'STANDARD-T3',
    'STANDARD-T4',
    'STANDARD-T5',
    'STANDARD-T6',
    'PREMIUM-T1',
    'PREMIUM-T2',
    'PREMIUM-T3',
    'PREMIUM-T4',
    'PREMIUM-T5',
    'PREMIUM-T6',
    'PREMIUM-T7',
  ],
  fontName: [ 'Garamond', 'Helvetica', 'Times New Roman' ].sort(),
  color: [
    {
      colorName: 'Black',
      colorCode: '#000000',
    },
    {
      colorName: 'Classic Blue',
      colorCode: '#34568B',
    },
    {
      colorName: 'Living Coral',
      colorCode: '#FF6F61',
    },
    {
      colorName: 'Ultra Violet',
      colorCode: '#6B5B95',
    },
    {
      colorName: 'Greenery',
      colorCode: '#88B04B',
    },
    {
      colorName: 'Rose Quartz',
      colorCode: '#F7CAC9',
    },
    {
      colorName: 'Serenity',
      colorCode: '#92A8D1',
    },
    {
      colorName: 'Marsala',
      colorCode: '#955251',
    },
    {
      colorName: 'Radiant Orchid',
      colorCode: '#B565A7',
    },
    {
      colorName: 'Emerald',
      colorCode: '#009B77',
    },
  ],
};

// traits: [
//   {
//     name: 'Benevolent',
//     score: 0.75,
//   },
//   {
//     name: 'Dependable',
//     score: 0.67,
//   },
//   {
//     name: 'Procedural',
//     score: 0.6,
//   },
//   {
//     name: 'Generous',
//     score: 0.57,
//   },
//   {
//     name: 'Empathetic',
//     score: 0.5,
//   },
//   {
//     name: 'Punctual',
//     score: 0.5,
//   },
//   {
//     name: 'Spontaneous',
//     score: 0.43,
//   },
//   {
//     name: 'Curious',
//     score: 0.4,
//   },
//   {
//     name: 'Dedicated',
//     score: 0.4,
//   },
//   {
//     name: 'Organized',
//     score: 0.4,
//   },
// ],
