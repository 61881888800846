import { IColor, IRcProduct } from 'src/interfaces';
import { CoreModel } from './Core.model';

export class SubscriptionModel extends CoreModel {
  productClass: string = '';
  productName: string = '';
  productDescr: string = '';
  mayUseTraitCount: number = 0;
  templates: string[] = [];
  fonts: string[] = [];
  colors: IColor[] = [];

  constructor(data?: Partial<SubscriptionModel>) {
    super(data);
    Object.assign(this, data);
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<IRcProduct>) {
    return new SubscriptionModel({
      ...data,
      templates: data.templateName,
      fonts: data.fontName,
      colors: data.color,
    });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
