import { Checkbox } from 'antd';
import React, { FC } from 'react';

interface Props {
  disabled?: boolean;
  value?: boolean;
  onChange?: Function;
}

const AntDCheckbox: FC<Props> = ({ value, ...props }) => (
  <Checkbox checked={value} {...(props as any)} onChange={e => props.onChange(e.target.checked)} />
);

export default AntDCheckbox;
