export enum EDITOR_TYPES {
  HIGH_SCHOOLS = 'highSchools',
  COLLEGES = 'colleges',
  EXPERIENCE = 'experience',
  EXTRA_CURRICULAR = 'extracurricular',
  HOBBIES = 'hobbies',
  LANGS = 'langs',
  MAIL_ADDRESS = 'mailAddress',
  ORGS = 'orgs', // Check this
  PROF_SUMMARY = 'profSummary',
  TECH_SKILLS = 'techSkills',
  VOLUNTEERING = 'volunteering',
  CUSTOM_SECTIONS = 'customSections',
}
