export class UserModel {
  firstName?: any;
  lastName?: any;
  address: string;
  city: string;
  state: string;
  phoneNo?: any;
  email?: any;
  zip: string;
  // Summary
  summary: string;

  constructor(data?: Partial<UserModel>) {
    Object.assign(this, data);
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<UserModel>) {
    return new UserModel(data);
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
