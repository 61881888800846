import { ITitleAndLines } from '@job-commander/shared';
import { IExperience } from 'src/interfaces';
import { CoreModel } from './Core.model';

export class ExperienceModel extends CoreModel implements ITitleAndLines {
  company: string;
  title: string;
  yearsExperChoiceNo: number;
  induJobId: number;
  induId: number;
  city: string;
  state: string;

  constructor(data?: Partial<ExperienceModel>) {
    super(data);
    Object.assign(this, data);
  }

  public get lines(): string[] {
    return [ this.company, this._duration ];
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<IExperience>) {
    return new ExperienceModel({ ...data, ...this.deserializeDates(data) });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
