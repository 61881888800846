import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

/* istanbul ignore next */
export default class HttpClient {
  private httpClient: AxiosInstance;

  constructor(params?: AxiosRequestConfig) {
    this.httpClient = axios.create({ ...params });
    this.httpClient.interceptors.response.use(this.successHandler.bind(this), this.errorHandler.bind(this));
  }

  /**
   * withToken
   */
  public withToken(accessToken: string): HttpClient {
    this.httpClient.interceptors.request.use(
      config => {
        config.headers.Authorization = `bearer ${accessToken}`;
        return config;
      },
      error => Promise.reject(error)
    );
    return this;
  }

  public get(url: string, options?: any): Promise<any> {
    return this.httpClient.get(url, options);
  }

  public put<T = any>(url: string, body: Body, options?: any): Promise<T> {
    return this.httpClient.put(url, body, options);
  }

  public post<T = any>(url: string, body: Body, options?: any): Promise<T> {
    return this.httpClient.post(url, body, options);
  }

  public delete<T = any>(url: string, body: Body): Promise<T> {
    return this.httpClient.delete(url, { data: body });
  }

  private successHandler(response: AxiosResponse) {
    return response;
  }

  private async errorHandler(error: AxiosError | any): Promise<AxiosError<any>> {
    if (!error.isAxiosError) {
      return error;
    }
    const { data } = error.response as any;
    return Promise.reject({
      hasError: true,
      error: true, // deprecated
      statusCode: error.errorCode || error.status || data.errorCode,
      statusText: data?.trMsg || error.trMsg || error.statusText,
      data,
    });
  }

  // Catch error responses.
  // In error responses we have only Client errors (400–499) and Server errors (500–599)
  private getApiErrorMessage(status: number, data: any): string {
    const apiErrorMessage = data?.Errors?.map((error: any) => error.Message)?.join(', ');
    switch (true) {
      case status === 400:
        return data?.title || apiErrorMessage;
      case status >= 403 && status <= 500:
        return data?.message || data?.messages?.join(', ') || apiErrorMessage;
      default:
        return 'Error Occurred.';
    }
  }
}
