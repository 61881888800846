import React, { FC, useContext, useEffect, useState } from 'react';
import { resumeElementModelMap } from 'src/mappers/ResumeElement.mapper';
import { ResumeEditorSectionModel, ResumeModel, ResumeSectionModel } from 'src/models';
import { SubscriptionModel } from 'src/models/Subscription.model';
import { resumeData, userSubscription } from './ResumeData';

interface IResumeContext {
  resume: ResumeModel;
  subscription: SubscriptionModel;
  updateResume: (resume: ResumeModel) => void;
  updateColor: (color: string) => void;
  updateFont: (font: string) => void;
  changeTemplate: (templateName: string) => void;
  addResumeSection: (section: ResumeSectionModel) => void;
}

const ResumeContext = React.createContext<IResumeContext>({
  resume: new ResumeModel(),
  subscription: new SubscriptionModel(),
  updateResume: (resume: ResumeModel) => {},
  updateFont: (color: string) => {},
  updateColor: (font: string) => {},
  changeTemplate: () => {},
  addResumeSection: () => {},
});

const ResumeContextProvider: FC<any> = ({ children }) => {
  // Laster this one will come
  const [ subscription, setSubscription ] = useState<SubscriptionModel>(new SubscriptionModel());
  const [ resume, setResume ] = useState<ResumeModel>(new ResumeModel());

  useEffect(() => {
    setResume(getResume());
    // set subscription
    setSubscription(SubscriptionModel.deserialize(userSubscription));
  }, []);

  const getResume = (): ResumeModel => {
    const sections: any = Object.keys(resumeData).reduce((total, _key, currentIndex) => {
      // Need this casing T type model from mapper
      const Model = resumeElementModelMap[_key];
      total[_key] = new ResumeEditorSectionModel({
        order: currentIndex,
        data: Array.isArray(resumeData[_key])
          ? resumeData[_key].map(x => Model.deserialize(x))
          : Model.deserialize(resumeData[_key]),
      });
      return total;
    }, {});
    return new ResumeModel({ sections });
  };

  // Add new Section to resume by clicking on the item
  const addResumeSection = (section: ResumeSectionModel) => {
    setResume(r => new ResumeModel({ ...r, sections: { ...r.sections, [section.editorType]: section } }));
  };

  const contextValue = {
    resume,
    subscription,
    addResumeSection,
    changeTemplate: (templateName: string) => setResume(r => new ResumeModel({ ...r, templateName })),
    updateResume: (resume: ResumeModel) => setResume(resume),
    updateColor: (color: string) => setResume(r => new ResumeModel({ ...r, color })),
    updateFont: (font: string) => setResume(r => new ResumeModel({ ...r, font })),
  };
  return <ResumeContext.Provider value={contextValue}>{children}</ResumeContext.Provider>;
};

export const useResumeContext = () => useContext(ResumeContext);

// HOC for providing context
export const withResumeContext = Component => {
  const wrapperComponent = props => (
    <ResumeContextProvider>
      <Component {...props} />
    </ResumeContextProvider>
  );
  wrapperComponent.displayName = `withResumeContext(${Component.displayName || Component.name || 'Component'})`;
  return wrapperComponent;
};
