import { ITitleAndLines } from '@job-commander/shared';
import { CoreModel } from './Core.model';

export class LanguageModel extends CoreModel implements ITitleAndLines {
  order: number;
  langLevel: number;

  constructor(data?: Partial<LanguageModel>) {
    super(data);
    Object.assign(this, data);
  }

  public get title(): string {
    return this.name;
  }

  public get lines(): string[] {
    return [ 'lines' ];
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<LanguageModel>) {
    return new LanguageModel({ ...data, ...this.deserializeDates(data) });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
