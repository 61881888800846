import {
  ExperienceModel,
  ResumeEditorSectionModel,
  CollegeModel,
  ExtracurricularModel,
  SchoolModel,
  HobbiesModel,
  LanguageModel,
  UserModel,
  OrganizationModel,
  TechSkillModel,
  VolunteeringModel,
  CustomSectionModel,
} from '../models';

export class ResumeElementsModel {
  colleges: ResumeEditorSectionModel<CollegeModel[]> = null;
  experience: ResumeEditorSectionModel<ExperienceModel[]> = null;
  extracurricular: ResumeEditorSectionModel<ExtracurricularModel[]> = null;
  highSchools: ResumeEditorSectionModel<SchoolModel[]> = null;
  hobbies: ResumeEditorSectionModel<HobbiesModel[]> = null;
  langs: ResumeEditorSectionModel<LanguageModel[]> = null;
  mailAddress: ResumeEditorSectionModel<UserModel> = null;
  orgs: ResumeEditorSectionModel<OrganizationModel[]> = null;
  profSummary: ResumeEditorSectionModel<UserModel> = null;
  techSkills: ResumeEditorSectionModel<TechSkillModel[]> = null;
  volunteering: ResumeEditorSectionModel<VolunteeringModel[]> = null;
  customSections: ResumeEditorSectionModel<CustomSectionModel[]> = null;

  constructor(data?: Partial<ResumeElementsModel>) {
    Object.assign(this, data);
  }

  // MAP Model from API to UI
  public static deserialize?(data?: Partial<CustomSectionModel>) {
    return new CustomSectionModel(data);
  }

  // MAP Model from UI to API
  public serialize?() {
    return { ...this };
  }
}
