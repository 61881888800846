import { HttpClient, useAlert } from '@job-commander/shared';
import { useState } from 'react';
import { baseAPI } from '../config';
import { apiUrls } from './apiUrls';

export interface IDataService {
  setAccessToken: (token: string) => void;
  login: (request: any) => Promise<any>;
  register: (request: any) => Promise<any>;
  resendPhoneCode: (request: any) => Promise<any>;
  findColleges: (searchText: string) => Promise<any>;
}

export const useDataService = (): IDataService => {
  const [ accessToken, setAccessToken ] = useState('');

  const notification = useAlert();

  // use login request
  const login = async request => {
    request.phoneNo = (request.phoneNo || '').replace(/\D/gi, '');
    request.phoneCode = request.phoneCode || '';

    const http = new HttpClient({ baseURL: baseAPI.resumeCommander });
    const response = await http.post(apiUrls.login, request);
    if (response.hasError) {
      notification.error('Something Went Wrong!', response.statusText);
      return;
    }
    return response.data;
  };

  // use login request
  const register = async (request: any) => {
    const http = new HttpClient({ baseURL: baseAPI.resumeCommander });
    const response = await http.get(apiUrls.login);
    if (response.hasError) {
      notification.error('Something Went Wrong!', response.statusText);
      return;
    }
    return http.get(apiUrls.login);
  };

  // use login request
  const resendPhoneCode = async (request: any) => {
    const http = new HttpClient({ baseURL: baseAPI.resumeCommander });
    const response = await http.get(apiUrls.login);
    if (response.hasError) {
      notification.error('Something Went Wrong!', response.statusText);
      return;
    }
    return http.get(apiUrls.login);
  };

  // findColleges()
  const findColleges = async searchText => {
    const http = new HttpClient({ baseURL: baseAPI.resumeCommander }).withToken(accessToken);
    const result = await http.post(apiUrls.findColleges, { prefix: searchText, amount: 10 } as any);
    const { data } = result;
    if (result.hasError) {
      notification.error('Something Went Wrong!', result.statusText);
      return;
    }
    const colleges = data.colleges || [];
    return colleges.map(item => ({
      value: item.choiceNo,
      label: item.text,
    }));
  };

  return { setAccessToken, login, register, resendPhoneCode, findColleges };
};
