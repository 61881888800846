import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import ReactQuill, { Quill } from 'react-quill';
// import { styles } from './RichTextEditor.style';
// import { Colors } from './Colors';
import { Sources } from 'quill';

const toolbarOptions = {
  toolbar: {
    container: [
      [{ size: [] }],
      [{ header: [ 1, 2, 3, 4, 5, 6, false ] }], // text size
      [ 'bold', 'italic', 'underline' ], // toggled buttons
      [ 'link' ], // add links to url
      [{ list: 'ordered' }, { list: 'bullet' }], // ordering
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ color: Colors }, { background: [] }], // dropdown with defaults from theme
      [ 'clean' ], // remove formatting button
    ],
  },
};

const icons = Quill.import('ui/icons');

type Props = {
  onChange?: Function;
  onFocus?: Function;
  value?: any;
  isEditable?: boolean;
  customButton?: {
    label: string;
    onClick: Function;
  };
};

const RichTextEditor: FC<Props> = ({ onChange, value, onFocus, isEditable, customButton }) => {
  const editorClass = classNames({ 'react-Quill': true });
  return (
    <div id="richTextEditor" className="rich-text-editor">
      <ReactQuill
        className={editorClass}
        readOnly={!isEditable}
        theme="snow"
        bounds={'#richTextEditor'}
        value={value}
        onChange={(content: string, _, source: Sources) => source !== 'api' && onChange(content)}
        modules={_getModules()}
        onFocus={() => onFocus()}
      />
    </div>
  );

  function _getModules() {
    const _toolbar: any = Object.assign({}, toolbarOptions.toolbar);

    /* istanbul ignore next */
    if (customButton) {
      _toolbar.container = _toolbar.container.concat([[ 'customButton' ]]);
      icons['customButton'] = `${customButton?.label}`;
      _toolbar.handlers = { customButton: customButton.onClick };
    }

    return { toolbar: useMemo(() => _toolbar, []), clipboard: { matchVisual: false } };
  }
};
export default RichTextEditor;
