import { Utilities } from '@job-commander/shared';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'src/enums';

export class CoreModel {
  _id: string = Utilities.createId();
  candId: number;
  seq: number = 0;
  order: number = 0; // display order in list
  name: string = '';
  startDate: moment.Moment = null;
  present: boolean = false;
  endDate: moment.Moment = null;
  gradYear: moment.Moment = null;
  summary: string = '';

  constructor(data?: Partial<CoreModel>) {
    Object.assign(this, data);
  }

  public get _startDate(): string {
    return this.startDate?.toISOString();
  }

  public get _endDate(): string {
    return this.endDate?.toISOString();
  }

  public get _grandYear(): string {
    return this.gradYear?.year().toString();
  }

  /**
   * Get duration in format
   */
  public get _duration(): string {
    return `${this.startDate?.format(DATE_TIME_FORMAT.DATE_WITH_YEAR)} To ${
      this.present ? 'Present' : this.endDate?.format(DATE_TIME_FORMAT.DATE_WITH_YEAR)
    }`;
  }

  // MAP Model from API to UI
  public static deserializeDates(data?) {
    return {
      startDate: Utilities.getMomentDate(data?.startDate),
      endDate: Utilities.getMomentDate(data?.endDate),
      gradYear: Utilities.getMomentDate(data?.gradYear, DATE_TIME_FORMAT.YEAR_ONLY),
    };
  }
}
