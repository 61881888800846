import { ITitleAndLines } from '@job-commander/shared';
import { CoreModel } from './Core.model';

export class ExtracurricularModel extends CoreModel implements ITitleAndLines {
  role: string;
  org: string;
  location: string;
  summary: string;
  extraCurMonthsChoiceNo: number;

  constructor(data?: Partial<ExtracurricularModel>) {
    super(data);
    Object.assign(this, data);
  }

  public get title(): string {
    return this.role;
  }

  public get lines(): string[] {
    return [ this.org, this._duration ];
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<ExtracurricularModel>) {
    return new ExtracurricularModel({ ...data, ...this.deserializeDates(data) });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
