import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Typography, Popconfirm } from 'antd';
const { Title, Paragraph } = Typography;
import './EditorItem.less';
import { useNotification } from '../../context';

type Item = {
  title: string;
  lines: string[];
  scaffold: boolean;
  removable: boolean;
  required: boolean | Function;
};

type Props = {
  item: Item;
  readOnly: boolean;
  disableRemove?: boolean;
  isEmployee?: Boolean;
  onRemoveClick?: Function;
  onClick?: Function;
  onJobExtend?: React.MouseEventHandler<HTMLElement>;
};

const EditorItem: FC<Props> = ({ item, readOnly, onClick, disableRemove, isEmployee, onRemoveClick, onJobExtend }) => {
  const notification = useNotification();
  const message = isEmployee
    ? 'Are you sure you want to delete this job? If deleted, the job will be removed from JobCommander.'
    : 'Are you sure you want to remove this item?';
  const { title, lines, scaffold } = item;

  const _onItemClick = (item, event) => {
    try {
      const popover = event.target.closest('.ant-popover');
      if (popover != null) {
        return;
      }
    } catch (e) {
      notification.exception('EditorItem/_onItemClick', e);
      return;
    }

    if (readOnly === true) return;

    if (typeof onClick === 'function') {
      onClick(item);
    }
  };

  const _onRemoveClick = (item, e) => {
    if (readOnly === true) return;

    if (typeof onRemoveClick === 'function') {
      onRemoveClick(item);
    }
  };

  const isRequired = () => {
    if (readOnly === true) return false;

    if (typeof item.required === 'function') {
      return item.required();
    }

    return item.required;
  }; //- isRequired()

  return (
    <div
      className={`editor-ex-item ${scaffold === true ? 'scaffold' : ''} ${readOnly === true ? 'readonly' : ''}`}
      onClick={e => _onItemClick(item, e)}
    >
      <Title level={5}>{title}</Title>
      {lines.map((line, index) => (
        <Paragraph key={index}>{line}</Paragraph>
      ))}
      {isRequired() && <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#fbae17' }} />}
      {item.removable && !readOnly && !disableRemove && (
        <Popconfirm
          title={message}
          okText="Yes"
          cancelText="No"
          onConfirm={e => {
            e.stopPropagation();
            _onRemoveClick(item, e);
          }}
        >
          <FontAwesomeIcon icon={faMinusCircle} style={{ color: '#fbae17' }} onClick={e => e.stopPropagation()} />
        </Popconfirm>
      )}
    </div>
  );
};

EditorItem.defaultProps = {
  disableRemove: false,
  // scaffold: false,
  // required: false,
  readOnly: false,
};

// Export
export { EditorItem };
