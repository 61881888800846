import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import process from 'process';
import React, { FC } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from 'react-google-login';
import './SocialLogin.less';

type Props = {
  onLoginResponse: (response: any, isGoogle: boolean) => void;
  onSocialSignup?: Function; // if user needs to signup
};

const SocialLogin: FC<Props> = props => {
  const { signIn } = useGoogleLogin({
    cookiePolicy: 'single_host_origin',
    clientId: process.env.REACT_APP_GOOGLE_ROOT || '',
    onSuccess: result => props.onLoginResponse(result, true),
    onFailure: e => console.log('ERROR', e),
  });

  return (
    <div id="social-wrapper">
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP}
        autoLoad={false}
        fields="name,email,picture"
        callback={(result: any) => props.onLoginResponse(result, false)}
        render={(renderProps: any) => (
          <Button
            id="facebook"
            size="middle"
            icon={<FontAwesomeIcon icon={faFacebookF} />}
            onClick={renderProps.onClick}
          >
            Facebook
          </Button>
        )}
      />
      <Button id="google" size="middle" onClick={signIn} icon={<FontAwesomeIcon icon={faGoogle} />}>
        Google
      </Button>
    </div>
  );
};

export default SocialLogin;
