import { EDITOR_TYPES } from 'src/enums';
import {
  CollegeModel,
  ExperienceModel,
  LanguageModel,
  ExtracurricularModel,
  SchoolModel,
  HobbiesModel,
  UserModel,
} from '../models';

export type DataModel =
  | LanguageModel
  | CollegeModel
  | ExperienceModel
  | ExtracurricularModel
  | SchoolModel
  | UserModel
  | HobbiesModel;

export class ResumeSectionModel {
  title: string = '';
  lines: string[] = [];
  editorType: EDITOR_TYPES = EDITOR_TYPES.PROF_SUMMARY;
  required: boolean = false;
  data: DataModel;

  constructor(data?: Partial<ResumeSectionModel>) {
    Object.assign(this, data);
  }
}
