import React from 'react';
import PropTypes from 'prop-types';

import { EditorItem } from './EditorItem';

import './ListEditor.less';
import { SortableList } from '../SortableList';

const getScaffoldRequired = (scaffold, items = []) => {
  if (scaffold == null || scaffold.data == null) {
    return false;
  }

  const data = scaffold.data || {};
  if (data.type == null) {
    if (items.length > 0) return false;
    return scaffold.required || false;
  } else {
    if (items.length == 0) return scaffold.required || false;
    return !items.some(item => item.data.type === data.type);
  }
};

// ListEditor()
// scaffolds : Are the dummy place holders used to add new items in list
const ListEditor = ({
  items,
  scaffolds,
  readOnly,
  onItemClick,
  onRemoveClick,
  disableRemove,
  isEmployee,
  isHalfFlex,
  isSortable,
  onSortEnd,
}) => (
  <div className={`list-editor-container ${isHalfFlex ? 'half-flex' : ''}`}>
    {!readOnly &&
      scaffolds.map((item, index) => (
        <EditorItem
          key={index}
          item={{ ...item, scaffold: true, removable: false, required: getScaffoldRequired(item, items) }}
          readOnly={readOnly}
          onClick={onItemClick}
        />
      ))}
    <SortableList
      isSortable={isSortable}
      listItems={items}
      onSortEnd={onSortEnd}
      helperClass="sortable-helper"
      renderItem={(item, index) => (
        <EditorItem
          key={index}
          item={item}
          readOnly={readOnly}
          onClick={onItemClick}
          onRemoveClick={onRemoveClick}
          disableRemove={disableRemove}
          isEmployee={isEmployee}
        />
      )}
    />
  </div>
); //- ListEditor()

const scaffoldShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(PropTypes.string),
  editorType: PropTypes.string,
  required: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
});

// PropTypes
ListEditor.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      lines: PropTypes.arrayOf(PropTypes.string),
      editorType: PropTypes.string,
      required: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
      removable: PropTypes.bool,
    })
  ),
  readOnly: PropTypes.bool,
  scaffolds: PropTypes.arrayOf(scaffoldShape),
  onItemClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  disableRemove: PropTypes.bool,
  isEmployee: PropTypes.bool,
  isHalfFlex: PropTypes.bool,
  isSortable: PropTypes.bool, // If item can be sort using React DND
  onSortEnd: PropTypes.func, // If item can be sort using React DND
};

ListEditor.defaultProps = {
  disableRemove: false,
  required: true,
  readOnly: false,
  scaffolds: [],
  items: [],
  isEmployee: false,
  isHalfFlex: false,
  isSortable: false,
};

// Export
export { ListEditor };
