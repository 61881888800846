import React, { FC, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { dropDownOptions } from '../../../assets/data';

type Props = {
  dropdownName?: string;
  multiple?: boolean;
  maxSelections?: number;
  initialValue?: any; //PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  onSelect?: any; // Change it with function
  placeholder?: string;
  disabled?: boolean;
  [key: string]: any;
  dropdown?: any;
};

// AsyncSelect()
const AsyncSelect: FC<Props> = ({
  dropdownName,
  multiple,
  maxSelections,
  initialValue,
  onSelect,
  dropdown,
  ...rest
}) => {
  const [ selected, setSelected ] = useState(initialValue || []);
  // const [ loaded, options ] = loadOptions.useBeckon(dropdownName);

  const options = dropDownOptions[dropdownName] || dropdown || [];

  // check initial values refresh select options if initial value changed
  useMemo(() => {
    setSelected(initialValue || []);
  }, [ initialValue ]);

  // handleOnSelect()
  const handleOnSelect = value => {
    if (maxSelections < 0 || selected.length < maxSelections) {
      setSelected([ ...selected, value ]);
    }

    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  }; //- handleOnSelect()

  // handleOnDeselect()
  const handleOnDeselect = value => {
    setSelected(selected.filter(i => i !== value));
  }; //- handleOnDeselect

  // getOptions()
  const getOptions = () => {
    if (multiple !== true) {
      return Array.isArray(options) ? options : [];
    } else {
      const newOptions = (Array.isArray(options) ? options : []).map(i => ({
        ...i,
        disabled: false,
      }));

      if (maxSelections < 0 || selected.length < maxSelections) {
        return newOptions;
      }

      return newOptions.map(item => ({
        ...item,
        disabled: selected.includes(item.value) ? false : true,
      }));
    }
  }; //- getOptions()

  return (
    <Select
      {...rest}
      // loading={!loaded}
      mode={multiple == true ? 'multiple' : null}
      options={getOptions()}
      onDeselect={multiple === true ? handleOnDeselect : null}
      onSelect={multiple === true ? handleOnSelect : onSelect}
    />
  );
};

AsyncSelect.propTypes = {
  dropdownName: PropTypes.string,
  multiple: PropTypes.bool,
  maxSelections: PropTypes.number,
  initialValue: PropTypes.oneOfType([ PropTypes.array, PropTypes.number ]),
  onSelect: PropTypes.func,
};

AsyncSelect.defaultProps = {
  multiple: false,
  maxSelections: -1,
};

export default AsyncSelect;
