import { ITitleAndLines } from '@job-commander/shared';
import { CoreModel } from './Core.model';

export class SchoolModel extends CoreModel implements ITitleAndLines {
  present: boolean;
  gpa: string;
  feats: string;
  gpaChoiceNo: number;

  constructor(data?: Partial<SchoolModel>) {
    super(data);
    Object.assign(this, data);
  }

  public get title(): string {
    return this.name;
  }

  public get lines(): string[] {
    return [ this.feats, this._grandYear ];
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<SchoolModel>) {
    return new SchoolModel({ ...data, ...this.deserializeDates(data) });
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
