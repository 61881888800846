import { creditScore } from './creditScore';
import { debt } from './debt';
import { degrees } from './degrees';
import { doctorates } from './doctorates';
import { education } from './education';
import { experience } from './experience';
import { expertise } from './expertise';
import { gpa } from './gpa';
import { industry } from './industry';
import { interest } from './interest';
import { jobType } from './jobType';
import { loanServicer } from './loanServicer';
import { masters } from './masters';
import { months } from './months';
import { numEmployees } from './numEmployees';
import { payType } from './payType';
import { relocation } from './relocation';
import { travel } from './travel';
import { workWeek } from './workWeek';
import { langExpertise } from './langExpertise';
export const dropDownOptions = {
  creditScore,
  debt,
  degrees,
  doctorates,
  education,
  experience,
  expertise,
  gpa,
  industry,
  interest,
  jobType,
  loanServicer,
  masters,
  months,
  numEmployees,
  payType,
  relocation,
  travel,
  workWeek,
  langExpertise,
};
