import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Steps, Typography } from 'antd';
import React, { FC, MouseEventHandler } from 'react';
import { Sticky, StickyContainer } from 'react-sticky';
import './StickyLayoutButtons.less';

const { Title } = Typography;

type Props = {
  children: any;
  currentStep: number;
  title: string;
  steps: { title: string; description: string }[];
  nextButtonTitle: string;
  onNextButtonClick?: MouseEventHandler<HTMLElement>;
  onBackButtonClick?: MouseEventHandler<HTMLElement>;
  disableNext?: Function;
  isLoading?: boolean;
};

const StickyLayoutButtons: FC<Props> = ({
  currentStep,
  steps,
  onNextButtonClick,
  onBackButtonClick,
  nextButtonTitle,
  title,
  children,
  disableNext,
  isLoading,
}) => {
  return (
    <StickyContainer>
      <div className="wizard-wrapper">
        <Sticky>
          {({ style }) => (
            <div className="wizard-nav-bar" style={style}>
              <Button
                type="primary"
                disabled={currentStep == 0}
                onClick={onBackButtonClick}
                icon={<FontAwesomeIcon icon={faChevronLeft} />}
              >
                Back
              </Button>
              <div className="wizard-title">
                <Title level={4}>{title}</Title>
              </div>
              <Button
                type="primary"
                className="icon-right"
                disabled={disableNext(currentStep)}
                onClick={onNextButtonClick}
                loading={isLoading}
                icon={nextButtonTitle === 'Next' && <FontAwesomeIcon icon={faChevronRight} />}
              >
                {nextButtonTitle}
              </Button>
            </div>
          )}
        </Sticky>
        <div className="wizard-content-container">
          {steps.length > 1 && (
            <Steps current={currentStep}>
              {steps.map((step, keyIdx) => (
                <Steps.Step key={keyIdx} title={step.title} description={step.description} />
              ))}
            </Steps>
          )}
          <div className="wizard-content">
            {React.Children.map(children, (child, index) => (index === currentStep ? React.cloneElement(child) : null))}
          </div>
        </div>
      </div>
    </StickyContainer>
  );
};

StickyLayoutButtons.defaultProps = {
  currentStep: 0,
  nextButtonTitle: 'Next',
  steps: [],
  disableNext: () => false,
};

export default StickyLayoutButtons;
