import React, { FC, useState } from 'react';
import { Step } from 'react-joyride';
import JoyRideTutorial from './JoyRideTutorial';

interface IJoyRideContext {
  isRunning: boolean;
  stepIndex: number;
  setStepIndex: Function;
  startRide: (index) => void;
  stopRide: () => void;
  next: () => void;
  prev: () => void;
}

export const JoyRideContext = React.createContext<IJoyRideContext>({
  isRunning: false,
  stepIndex: 0,
  setStepIndex: (index: number) => '',
  startRide: (index: number) => '',
  stopRide: () => '',
  prev: () => '',
  next: () => '',
});

type Props = {
  children: any;
  steps: Step[];
};

const JoyRideProvider: FC<Props> = ({ children, steps }) => {
  const [ stepIndex, setStepIndex ] = useState(0);
  const [ isRunning, setIsRunning ] = useState(false);
  const contextValue: IJoyRideContext = {
    isRunning,
    stepIndex,
    setStepIndex: index => {
      localStorage.setItem('step-index', index);
      setStepIndex(index);
    },
    startRide: index => {
      const isTutorialDone = localStorage.getItem('TutorialDone');
      if (isTutorialDone) {
        return;
      }
      setStepIndex(index);
      setIsRunning(true);
    },
    stopRide: () => setIsRunning(false),
    prev: () => setStepIndex(stepIndex - 1),
    next: () => setStepIndex(stepIndex + 1),
  };

  return (
    <JoyRideContext.Provider value={contextValue}>
      {children}
      <JoyRideTutorial steps={steps} />
    </JoyRideContext.Provider>
  );
};

export { JoyRideProvider };
