// Validate Zip Code
export const zipCodeValidator = value => {
  if (value?.length === 5 || value.length === 9) {
    return Promise.resolve();
  }
  return Promise.reject('Zip Code may only be 5 or 9 characters in length.');
};

//Validate Phone Number
export const phoneNoValidator = value => {
  if (value?.length === 10) {
    return Promise.resolve();
  }
  return Promise.reject('Phone Number must contain 10 digits');
};

export const stateValidator = value => {
  const _value = value?.trim();

  if (_value.length === 2) {
    return Promise.resolve();
  }
  return Promise.reject('State (FL, GA, CT, etc.")');
};
