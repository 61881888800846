import { ResumeElementsModel } from './ResumeElements.model';

export class ResumeModel {
  title: string = ''; // future
  font: string = 'Garamond';
  color: string = '#000000';
  templateName: string = 'BASIC-T1';
  sections: ResumeElementsModel = new ResumeElementsModel();

  constructor(data?: Partial<ResumeModel>) {
    Object.assign(this, data);
    this.sections = new ResumeElementsModel(data?.sections);
  }

  // MAP Model from API to UI
  public static deserialize(data?: Partial<ResumeModel>) {
    return new ResumeModel(data);
  }

  // MAP Model from UI to API
  public serialize() {
    return { ...this };
  }
}
